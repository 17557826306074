import call from "../../service/http";
import { AuthConstants } from "./index";
import AuthService from "./AuthService";
import { EventBus } from "../../utils/eventBus";

export default {
  namespaced: true,
  state: {
    terms: {},
    requirements: [],
    verified: "pending",
    alert: {
      status: "",
      message: "",
    },
    profile: {},
  },
  mutations: {
    SET_TERMS: (state, payload) => {
      state.terms = payload;
    },

    SET_REQUIREMENTS: (state, payload) => {
      state.requirements = payload;
    },

    SET_VERIFY: (state, payload) => {
      state.verified = payload;
    },

    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },

    SET_PROFILE: (state, payload) => {
      state.profile = payload;
    },
  },
  getters: {
    terms: (state) => state.terms,
    requirements: (state) => state.requirements,
    error: (state) => state.error,
    verified: (state) => state.verified,
    alert: (state) => state.alert,
    profile: (state) => state.profile,
  },
  actions: {
    getTerms: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", AuthConstants.terms)
        .then((res) => {
          commit("SET_TERMS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    getMembershipRequirements: ({ commit }) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", AuthConstants.requirements)
        .then((res) => {
          commit("SET_REQUIREMENTS", res.data.data);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    register: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.register, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("route-change", {
            name: "Otp",
            params: { id: btoa(JSON.stringify(res.data.data)) },
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    login: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.login, data)
        .then((res) => {
          console.log(res);
          AuthService.login(res.data.data.token, res.data.data.user);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          if (err.response.data.data) {
            Event.$emit("verify-account", {
              email: err.response.data.data,
            });
          }
        });
    },

    counterVerifyOtp: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.counterVerifyOtp, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          Event.$emit("route-change", {
            name: "Otp",
            params: { id: btoa(JSON.stringify(res.data.data)) },
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },



    requestActivation: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.requestActivation, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          EventBus.$emit("activation-success", res.data.data);
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    activate: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.activate, data)
        .then((res) => {
          AuthService.login(res.data.data.token, res.data.data.user);
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          console.log(err);
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    verify: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.verify, data)
        .then((res) => {
          console.log(res);
          commit("SET_LOADING", false, { root: true });
          AuthService.login(res.data.data.token, res.data.data.user);
        })
        .catch((err) => {
          commit("SET_VERIFY", "failed");
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    verifyOtp: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.verifyOtp, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          AuthService.login(res.data.data.token, res.data.data.user);
        })
        .catch((err) => {
          commit("SET_VERIFY", "failed");
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    sendOtp: ({ commit }, id) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("get", AuthConstants.sendOtp(id))
        .then(() => {
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          console.log(err);
          commit("SET_VERIFY", "failed");
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    forgotPassword: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.passwordReset, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: res.data.data,
          });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    resetPassword: ({ commit }, data) => {
      commit("SET_ALERT", null);
      commit("SET_LOADING", true, { root: true });
      call("post", AuthConstants.password, data)
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: res.data.data,
          });
          Event.$emit("route-change", { name: "login", params: {} });
        })
        .catch((err) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
        });
    },

    user: ({ commit }, silent = false) => {
      commit("SET_ALERT", null);
      // commit("SET_LOADING", true, { root: true });
      call("get", AuthConstants.user).then((res) => {
        AuthService.setUser(res.data.data, silent);
        commit("SET_LOADING", false, { root: true });
      });
    },

    logout: () => {
      call("post", AuthConstants.logout).then(() => {
        AuthService.logout();
      });
    },

    searchMember: (context, term) => {
      context.commit("SET_LOADING", true, { root: true });
      call("get", AuthConstants.searchMember(term))
        .then((res) => {
          context.commit("SET_PROFILE", res.data.data);
          context.commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          context.commit("SET_LOADING", false, { root: true });
          Event.$emit("toasting", {
            message: error.response.data.message,
            type: "danger",
          });
        });
    },
  },
};
