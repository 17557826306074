<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    color="#26c6da"
    style="background-color: #dcdcdc"
    @keypress.enter="login"
  >
    <v-row class="mt-2">
      <v-col cols="12" md="8" offset-md="2">
        <v-sheet elevation="2" rounded="xl" class="pa-10">
          <v-row>
            <v-alert
              dark
              outlined
              v-if="session"
              dense
              border="left"
              type="info"
              class="ml-6"
            >
              {{ session }}
            </v-alert>
            <v-col cols="12" md="6">
              <v-card flat>
                <v-img
                  :src="`data:image/png;base64,${$store.getters['Setup/companyInformation'].logo}`"
                  height="100"
                  contain
                  class="mb-5"
                />

                <v-card-title
                  class="d-flex flex-row justify-center font-weight-black"
                >
                  LOG IN
                </v-card-title>

                <v-card-subtitle class="text-center font-weight-black">
                  For existing {{ caption.login_caption }} with active accounts
                </v-card-subtitle>

                <v-card-text class="text-justify mt-2 pb-0">
                  <v-alert
                    outlined
                    v-if="$store.getters['Auth/alert'].status"
                    dense
                    border="left"
                    :type="
                      $store.getters['Auth/alert'].status === 'success'
                        ? 'success'
                        : 'error'
                    "
                    class="mb-10"
                  >
                    {{ $store.getters["Auth/alert"].message }}
                  </v-alert>

                  <v-text-field
                    dense
                    outlined
                    label="Email Address"
                    placeholder="Enter your Email Address"
                    v-model="formData.email"
                    ref="email"
                    persistent-placeholder
                    :rules="rules.email"
                  />

                  <v-text-field
                    dense
                    outlined
                    label="Password"
                    @keypress.enter="login"
                    placeholder="Enter your password"
                    persistent-placeholder
                    v-model="formData.password"
                    ref="password"
                    :rules="rules.password"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-card-text>

                <div class="d-flex justify-space-between align-center">
                  <v-btn
                    class="mt-n4 text-lowercase text-decoration-underline"
                    text
                    color="info"
                    :to="{ name: 'forgotPassword' }"
                  >
                    Forgot password?</v-btn
                  >

                  <v-btn v-if="verifyAccount"
                    class="mt-n4 text-lowercase text-decoration-underline"
                    text
                    color="primary"
                    @click="verifyUserAccount"
                  >
                    verify account</v-btn
                  >
                </div>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12">
                      <v-btn @click="login" color="primary" block>
                        Login
                      </v-btn>
                    </v-col>

                    <v-col cols="12" class="mt-n4">
                      <p class="text-center overline grey--text">OR</p>
                    </v-col>

                    <v-col md="6" class="mt-n10">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :to="{ name: 'activate' }"
                            color="info"
                            block
                            text
                            v-bind="attrs"
                            v-on="on"
                          >
                            Activate Account
                          </v-btn>
                        </template>
                        <span>
                          For members who have not accessed the portal before
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col md="6" class="mt-n10" v-if="caption.registration">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :to="{ name: 'categories' }"
                            color="secondary"
                            block
                            text
                            v-bind="attrs"
                            v-on="on"
                          >
                            Register As Member
                          </v-btn>
                        </template>
                        <span>For new members applications</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
              <v-divider />
            </v-col>
            <v-divider v-else vertical />

            <v-col cols="12" md="6">
              <v-container class="fill-height">
                <v-card flat>
                  <v-card-title class="justify-center font-weight-black mt-4">
                    Registered Members
                  </v-card-title>
                  <v-card-text class="mt-n6 justify-left">
                    <br />
                    <ul>
                      <li>Click <b>activate account</b> button</li>
                      <li class="mt-1">
                        Enter your email address which <b>should be the same</b
                        ><br />
                        as the one registered with
                        {{ caption.organisation_caption }}<b> (members only)</b>
                      </li>
                      <li class="mt-1">
                        Login to your email for an <b>activation link</b> sent
                        to you.
                      </li>
                      <li class="mt-1">
                        Click on the <b>link</b> sent to your email to set your
                        password and confirm
                      </li>
                      <li class="mt-1">Login to your <b>portal</b></li>
                    </ul>
                  </v-card-text>
                  <div v-if="caption.registration">
                    <v-card-title class="justify-center font-weight-black mt-4">
                      New Applicants
                    </v-card-title>
                    <v-card-text class="mt-n6 justify-left">
                      <br />
                      <ul>
                        <li>Click the <b> Register As Member</b> button</li>
                        <li class="mt-1">
                          Follow the process to register and create an account
                        </li>
                      </ul>
                    </v-card-text>
                  </div>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";

export default {
  name: "Login",
  mixins: [RouterMixin],
  data: function () {
    return {
      formData: {
        email: "",
        password: "",
      },
      showPassword: false,
      verifyAccount: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getSettings");
    });
  },
  computed: {
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [(v) => !!v || "Password is required"],
      };
    },

    orgName() {
      return process.env.VUE_APP_ORGANIZATION;
    },

    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    minorSettings() {
      return this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : false;
    },
    InactivityLogout() {
      return this.minorSettings.inactivityLogout;
    },

    caption() {
      return this.settings && this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : "";
    },
    session() {
      return window.localStorage.getItem("sessionExpiry");
    },
  },

  mounted() {
    Event.$on("verify-account", (param) => {
      this.verifyAccount = true;
      this.formData.email = param.email;
    });
  },

  methods: {
    login: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) this.$store.dispatch("Auth/login", { ...this.formData });
    },
    verifyUserAccount: function () {
      this.$store.dispatch("Auth/counterVerifyOtp", { ...this.formData });
    },
  },

  watch: {
    InactivityLogout: {
      handler: function () {
        if (this.InactivityLogout) {
          window.localStorage.setItem("Inactivity", this.InactivityLogout);
        }
      },
    },
  },
};
</script>

<style scoped>
.fonts {
  font-family: "Times New Roman";
}
</style>
